import { colors } from '@zipdrug/ui';
import React, { useState, useContext } from 'react';
import { Role } from 'contexts/utils/models';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { PlayCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { Table, Popover, Tooltip } from 'antd';
import { css, StyleSheet } from 'aphrodite/no-important';
import gql from 'graphql-tag';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { SessionContext } from 'contexts/SessionContextProvider';
import PageHeading from '../../ui/PageHeading';
import { InboundIcon, HangupIconColor } from '../../ui/CallCenter/svg';
import Button from '../../ui/CallCenter/components/Button';
import Timer from './Timer';
import './SupervisorPage.css';

const sx = StyleSheet.create({
  bold: {
    fontWeigt: 'bold',
  },
  flex: {
    display: 'flex',
  },
  pdg30: {
    padding: '0px 30px',
  },
  mgn20: {
    margin: '20px 0px',
  },
  width100: {
    width: '100%',
  },
  supervisorPage: {
    background: '#F2F2F2',
  },
  refresh: {
    display: 'inline-block',
    cursor: 'pointer',
    margin: '15px 0px',
    color: colors.bluePurple,
    fontWeight: 'bold',
    ':hover': {
      color: '#371895',
    },
  },
  clrBlack: {
    color: 'black',
  },
  actionIcon: {
    height: '2em',
    textAlign: 'center',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  noOutline: {
    outline: 'none',
  },
  patientLink: {
    color: '#5A2FD4',
    fontWeight: 'bold',
    cursor: 'pointer',
    ':hover': {
      color: '#371895',
    },
  },
});

export const GET_USERS = gql`
  query users($skip: Int, $limit: Int, $query: JSON) {
    users(skip: $skip, limit: $limit, query: $query) {
      data {
        id
        joined_at
        first_name
        last_name
        email
        title
        inbound_eligible
        call_center_user_status
        current_call {
          id
          recording_duration
          direction # inbound | outbound
        }
        current_call_id
        feature_flags
        activeConference {
          confFriendlyName
          conferenceSid
          conferenceCreatedAt
          participants {
            callSid
            type
            typeId
            # pharmacy | patient | care_manager | supervisor | payer_hotline
            typeId
            # prescriber | other_external_number
            name
            number
            coaching
            language
          }
        }
      }
      total
    }
  }
`;

const startingSize = 10;
const SupervisorPage = ({ history: { push } }) => {
  const {
    supervisingCallContext,
    setSupervisingCallContext,
    isCallCenterActivatedContext,
    callCenterUserStatusContext,
    setSelectedPatientId,
  } = useContext(CallCenterContext);
  const { userId } = useContext(SessionContext);

  const [currentPagination, setCurrentPagination] = useState({ skip: 0, limit: startingSize });
  const queryObj = {
    id: {
      $not: [userId],
    },
    roles: {
      type: {
        $not: [Role.PharmacistUser, Role.PharmacistAdmin],
      },
    },
  };
  const { loading: loadingUsers, data: userData, refetch } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      skip: 0,
      limit: startingSize,
      query: queryObj,
    },
  });

  const users = get(userData, 'users.data');
  const data = users
    ? users.map(user => {
        return {
          key: user.id,
          agent: `${user.first_name} ${user.last_name}`,
          status: user.call_center_user_status,
          current_call_id: user.current_call_id,
          direction: user.current_call && user.current_call.direction,
          startTime: Date.now(),
          conferenceCreatedAt: user.activeConference && user.activeConference.conferenceCreatedAt,
          participants: user.activeConference ? user.activeConference.participants : [],
        };
      })
    : [];

  const isEnrollerSupervising = record => {
    const currentClient = `client:${record.key}`;
    const [enroller] =
      record && record.participants.filter(ptcpnt => ptcpnt.number === currentClient);

    return enroller && enroller.coaching;
  };

  const goToPatient = patientId => {
    setSelectedPatientId(patientId);
    push('/members');
  };
  const columns = [
    {
      title: '',
      dataIndex: 'participants',
      width: 75,
      render: (participants, record, index) => {
        if (record.status !== 'oncall' || !isCallCenterActivatedContext) return '';

        if (isEnrollerSupervising(record)) {
          return null;
        }

        return (
          <div className={css(sx.actionIcon, sx.clrBlack)}>
            {get(supervisingCallContext, 'currentCallId') !== record.current_call_id &&
            !isEnrollerSupervising(record) ? (
              <Tooltip placement="bottom" title="Listen">
                <PlayCircleOutlined
                  style={{ fontSize: '23px', color: 'black', cursor: 'pointer' }}
                  onClick={() => {
                    if (callCenterUserStatusContext === 'oncall') {
                      // eslint-disable-next-line
                      alert('Sorry you cannot supervise while your on a call');

                      return;
                    }

                    const currentClient = `client:${record.key}`;
                    const enroller = participants.filter(ptcpnt => ptcpnt.number === currentClient);
                    const enrollerCallSid = enroller && enroller[0].callSid;
                    const currentCallId = data[index].current_call_id;

                    setSupervisingCallContext({
                      enrollerCallSid,
                      currentCallId,
                    });
                  }}
                />
              </Tooltip>
            ) : (
              <HangupIconColor />
            )}
          </div>
        );
      },
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
      key: 'agent',
      render: text => <strong className={css(sx.clrBlack)}>{text}</strong>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        const currentClient = `client:${record.key}`;
        const [enroller] =
          record && record.participants.filter(ptcpnt => ptcpnt.number === currentClient);

        const statusMap = {
          oncall: 'On Call',
          available: 'Available',
          away: 'Away',
          offline: 'Offline',
          unavailable: 'Unavailable',
          postcall: 'Post call',
        };
        const formattedStatus = enroller && enroller.coaching ? 'Supervising' : statusMap[status];

        return <div>{formattedStatus}</div>;
      },
    },
    {
      title: 'Direction',
      dataIndex: 'direction',
      key: 'direction',
      render: direction => {
        return (
          <div>
            {
              {
                inbound: (
                  <Tooltip placement="bottom" title="Inbound Call">
                    <InboundIcon />
                  </Tooltip>
                ),
                outbound: (
                  <Tooltip placement="bottom" title="Outbound Call">
                    <InboundIcon style={{ transform: 'rotate(180deg)' }} />
                  </Tooltip>
                ),
                none: '-',
              }[direction]
            }
          </div>
        );
      },
    },
    {
      title: 'Duration',
      key: 'conferenceCreatedAt',
      dataIndex: 'conferenceCreatedAt',
      render: (conferenceCreatedAt, record) => {
        return record.status === 'oncall' && conferenceCreatedAt ? (
          <Timer onCallStartTime={new Date(conferenceCreatedAt)} />
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Language',
      dataIndex: 'participants',
      render: participants => {
        const [patient] = participants.filter(ptcpnt => ptcpnt.type === 'patient');

        return <div className={css(sx.capitalize)}>{patient && patient.language}</div>;
      },
    },
    {
      title: 'Participant(s)',
      key: 'participants',
      dataIndex: 'participants',
      render: (participants, record) => {
        let content = [];
        const currentClient = `client:${record.key}`;

        const ptcpnts = participants.filter(ptcpnt => ptcpnt.number !== currentClient);

        ptcpnts.forEach((ptpnt, index) => {
          content.push(
            <div
              key={`${ptpnt.name}${index + 1}`}
              style={{
                marginRight: '30px',
              }}
            >
              <div
                tabIndex={0}
                role="button"
                title="Caller"
                className={css(sx.noOutline, ptpnt.type === 'patient' ? sx.patientLink : null)}
                onClick={() => {
                  if (ptpnt.type === 'patient') {
                    goToPatient(ptpnt.typeId);
                  }
                }}
              >
                <strong>{ptpnt.name}</strong>
              </div>
              <div>
                {
                  {
                    patient: 'Patient',
                    pharmacy: 'Pharmacy',
                    care_manager: 'Care Manager',
                    supervisor: 'Supervisor ',
                    payer_hotline: 'Payer Hotline',
                    prescriber: 'Prescriber ',
                    other_external_number: 'Other External Number',
                  }[ptpnt.type]
                }
              </div>
            </div>,
          );
        });

        if (ptcpnts.length > 3) {
          content = content.slice(0, 3);
        }

        const morePtcpnts = [];
        if (ptcpnts.length > 3) {
          for (let i = 3; i < ptcpnts.length; i += 1) {
            const currPtcpnt = ptcpnts[i];
            morePtcpnts.push(
              <div key={`${currPtcpnt.name}${i}`}>
                <div
                  tabIndex={0}
                  role="button"
                  title="Caller"
                  className={css(
                    sx.noOutline,
                    currPtcpnt.type === 'patient' ? sx.patientLink : null,
                  )}
                  onClick={() => {
                    if (currPtcpnt.type === 'patient') {
                      goToPatient(currPtcpnt.typeId);
                    }
                  }}
                >
                  <strong>{currPtcpnt.name}</strong>
                </div>
                <div>
                  {
                    {
                      patient: 'Patient',
                      enroller: 'PBS Agent',
                      pharmacy: 'Pharmacy',
                    }[currPtcpnt.type]
                  }
                </div>
                {i === ptcpnts.length - 1 ? null : <hr />}
              </div>,
            );
          }
        }

        return (
          <div
            className="fs-hide"
            style={{
              display: 'flex',
            }}
          >
            {content.map(elem => elem)}

            {ptcpnts.length > 3 && (
              <Popover content={morePtcpnts}>
                <Button type="green" shape="circle">
                  {ptcpnts.length - 3}
                </Button>
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  const updateQuery = (page, pageSize) => {
    const pagination = { skip: 0, limit: pageSize };
    setCurrentPagination(pagination);
    return refetch(pagination);
  };

  return (
    <div className={css(sx.flex, sx.supervisorPage)}>
      <div className={css(sx.width100)}>
        <PageHeading title="Call Monitoring" />

        <div className={css(sx.pdg30)}>
          <div
            tabIndex={0}
            role="button"
            title="Refresh data"
            className={css(sx.refresh)}
            onClick={() => refetch(currentPagination)}
          >
            <RedoOutlined /> Refresh Table
          </div>

          <div className={css(sx.mgn20)}>
            <Table
              loading={loadingUsers}
              columns={columns}
              dataSource={data}
              className="fs-hide"
              rowClassName={({ status }) => {
                return `table-row-${status}`;
              }}
              pagination={{
                pageSizeOptions: [5, 10, 15, 25, 50, 100],
                defaultPageSize: startingSize,
                showSizeChanger: true,
                total: get(userData, 'users.total', 0),
                onChange: updateQuery,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SupervisorPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(SupervisorPage);