/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { StyleSheet, css } from 'aphrodite';
import { Table, message, Button, Space } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import withRoles from 'hoc/withRoles';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_PHARMACY_NETWORKS,
  GET_PHARM_NETWORK_REPORT_FILE,
} from '../../graphql/getPharmacyNetworks';
import UPDATE_PHARMACY_NETWORK_TYPE from '../../graphql/updatePharmacyNetworkType';
import ADD_PHARMACY_NETWORK_TYPE from '../../graphql/addPharmacyNetworkType';
import LineOfBusinessToNameMapping from '../../constants/LineOfBusinessToNameMapping';
import EditPharmacyNetworkModal from '../EditPharmacyNetworkModal/EditPharmacyNetworkModal';
import AddPharmacyNetworkModal from '../AddPharmacyNetworkModal/AddPharmacyNetworkModal';
import DownloadReportConfirmationModal from '../DownloadReportConfirmationModal/DownloadReportConfirmationModal';
import generateVariables from 'dashboard/components/PharmacyList/graphql/GenerateVariables.util';

const sx = StyleSheet.create({
  marginWrapper: {
    margin: '10px',
  },
  tableOptionsContainer: {
    marginBottom: '20px',
  },
  tableOptionsButtonMargin: {
    marginRight: '16px',
  },
  disabledRow: {
    background: '#f5f5f5',
    opacity: '0.5',
  },
});

const PharmacyNetworkList = ({
  hasRole: { pharmacyBusinessDev, pharmacyBusinessDevAdmin, systemAdmin, projectManager },
}) => {
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tableActions, setTableActions] = useState({});
  const [editPharmacyNetworkRecord, setEditPharmacyNetworkRecord] = useState({});
  const [showEditPharmacyNetworkModal, setShowEditPharmacyNetworkModal] = useState(false);
  const [showAddPharmacyNetworkModal, setShowAddPharmacyNetworkModal] = useState(false);
  const [showDownloadReportConfirmationModal, setShowDownloadReportConfirmationModal] = useState(
    false,
  );

  const [
    getPharmacyNetworks,
    { loading: isFetchingPharmacyNetworks, refetch: refetchPharmacyNetworks },
  ] = useLazyQuery(GET_PHARMACY_NETWORKS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching pharmacy list: ${error.message}`);
    },
    onCompleted: ({ pharmacyNetworks }) => {
      const networks = pharmacyNetworks?.data || [];
      setData({ data: networks, total: pharmacyNetworks?.total || networks.length });
      setShowAddPharmacyNetworkModal(false);
    },
  });

  const [updatePharmacyNetworkType, { loading: isUpdatingPharmacyNetworkType }] = useMutation(
    UPDATE_PHARMACY_NETWORK_TYPE,
    {
      onError: error => {
        message.error(`Error Updating Pharmacy Network Type: ${error.message}.`);
      },
      onCompleted: ({ updatePharmacyNetworkType }) => {
        const clonedData = cloneDeep(data);
        const updatedPharmacyNetworkType = updatePharmacyNetworkType?.data;

        const resultData = clonedData.data.map(networkType => {
          if (updatedPharmacyNetworkType?.id === networkType.id) {
            networkType = {
              ...networkType,
              ...updatedPharmacyNetworkType,
            };
          }
          return networkType;
        });
        setData({ ...clonedData, data: resultData });
        setShowEditPharmacyNetworkModal(false);
        message.success('Successfully Updated Pharmacy Network.');
      },
    },
  );

  const [addPharmacyNetworkType, { loading: isAddingPharmacyNetworkType }] = useMutation(
    ADD_PHARMACY_NETWORK_TYPE,
    {
      onError: error => {
        message.error(`Error Adding Pharmacy Network Type: ${error.message}.`);
      },
      onCompleted: () => {
        refetchPharmacyNetworks();
        message.success('Successfully Updated Pharmacy Network.');
      },
    },
  );


  useEffect(() => {
    if (pharmacyBusinessDev || pharmacyBusinessDevAdmin || systemAdmin || projectManager) {
      fetchPharmacyNetworks();
    }
  }, []);

  const editPharmacyNetworks = record => {
    setEditPharmacyNetworkRecord(record);
    setShowEditPharmacyNetworkModal(true);
  };

  const submitEditPharmacyNetwork = payload => {
    updatePharmacyNetworkType({
      variables: {
        id: editPharmacyNetworkRecord.id,
        modifiers: {
          ...payload,
        },
      },
    });
  };

  const addPharmacyNetworkCancel = () => {
    setShowAddPharmacyNetworkModal(false);
  };

  const addPharmacyNetworks = () => {
    setShowAddPharmacyNetworkModal(true);
  };

  const submitAddPharmacyNetwork = payload => {
    addPharmacyNetworkType({
      variables: {
        modifiers: payload,
      },
    });
  };

  const editPharmacyNetworkCancel = () => {
    setEditPharmacyNetworkRecord({});
    setShowEditPharmacyNetworkModal(false);
  };

  const downloadReportConfirmationModalCancel = () => {
    setShowDownloadReportConfirmationModal(false);
  };

  const downloadReportConfirmation = () => {
    setShowDownloadReportConfirmationModal(true);
  };

  const [getReportUrl] = useLazyQuery(GET_PHARM_NETWORK_REPORT_FILE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching attachment: ${error.message}`);
    },
    onCompleted: ({ getPharmacyNetworksReportFile }) => {
      const url = getPharmacyNetworksReportFile?.data?.file_url;
      window.open(url, '_blank');
      setShowDownloadReportConfirmationModal(false);
    },
  });

  const generateColumns = () => {
    const headers = [
      {
        title: 'Pharmacy Network',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Line of Business',
        dataIndex: 'lob',
        key: 'lob',
        render: text => LineOfBusinessToNameMapping[text] || 'N/A',
      },
      {
        title: 'Payer',
        dataIndex: 'payer',
        key: 'payer',
        render: record => record?.name || 'N/A',
      },
      {
        render: record => {
          return (
            <Space align="end">
              <Button type="link" onClick={() => editPharmacyNetworks(record)}>
                Edit
              </Button>
            </Space>
          );
        },
      },
    ];
    return headers;
  };

  const fetchPharmacyNetworks = (payload = cloneDeep(tableActions), refetch = false) => {
    if (payload?.sorter && !payload?.sorter?.order) {
      payload.sorter = null;
    }

    const variables = generateVariables(payload);

    if (refetch) {
      refetchPharmacyNetworks({
        ...variables,
      });
    } else {
      getPharmacyNetworks({
        variables: {
          ...variables,
        },
      })
    }
  };

  const onTableChange = (pagination, filters, sorter, { action }) => {
    setTableActions({
      pagination,
      filters,
      sorter,
      action,
    });
    fetchPharmacyNetworks({ pagination, filters, sorter, action });
  };

  // Useful when you update an value and the filter/sort is not applied.
  const refreshList = () => {
    fetchPharmacyNetworks(tableActions, true);
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: data?.data,
      size: 'middle',
      onChange: onTableChange,
      rowKey: 'id',
      loading:
        isFetchingPharmacyNetworks || isUpdatingPharmacyNetworkType || isAddingPharmacyNetworkType,
      pagination: {
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: [25, 50, 100],
        total: data?.total || 0,
        current: tableActions?.pagination?.current || 1,
      },
    };

    return tableOptions;
  };

  return (
    <div className={css(sx.marginWrapper)}>
      <div className={css(sx.tableOptionsContainer)}>
        <Button type="secondary" className={css(sx.tableOptionsButtonMargin)} onClick={refreshList}>
          Refresh List
        </Button>
        <Button
          type="secondary"
          className={css(sx.tableOptionsButtonMargin)}
          onClick={addPharmacyNetworks}
        >
          Add Network
        </Button>
        {systemAdmin || pharmacyBusinessDevAdmin || pharmacyBusinessDev || projectManager ? (
          <Button
            type="secondary"
            className={css(sx.tableOptionsButtonMargin)}
            onClick={downloadReportConfirmation}
          >
            Download Report
          </Button>
        ) : null}
      </div>
      <Table {...getTableOptions()} />
      {showEditPharmacyNetworkModal && (
        <EditPharmacyNetworkModal
          pharmacyNetworkRecord={editPharmacyNetworkRecord}
          editPharmacyNetworkCancel={editPharmacyNetworkCancel}
          submitEditPharmacyNetwork={submitEditPharmacyNetwork}
          visible={showEditPharmacyNetworkModal}
        />
      )}
      {showAddPharmacyNetworkModal && (
        <AddPharmacyNetworkModal
          addPharmacyNetworkCancel={addPharmacyNetworkCancel}
          submitAddPharmacyNetwork={submitAddPharmacyNetwork}
          visible={showAddPharmacyNetworkModal}
        />
      )}
      {showDownloadReportConfirmationModal && (
        <DownloadReportConfirmationModal
          downloadPharmacyNetworkReportCancel={downloadReportConfirmationModalCancel}
          downloadPharmacyNetworkReport={getReportUrl}
          visible={showDownloadReportConfirmationModal}
        />
      )}
    </div>
  );
};

export default withRouter(withRoles(PharmacyNetworkList));
